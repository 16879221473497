import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { CheckCircle } from 'react-feather';

export default function AboutUs() {
  return (
    <section
      id="sobre-mi"
      className="relative z-10 px-5 pb-10 overflow-hidden bg-white pt-36 md:pb-20 md:pt-52"
    >
      <div className="w-full max-w-xl mx-auto md:max-w-5xl 2xl:max-w-7xl">
        <div className="flex flex-col items-center gap-5 lg:gap-20 xl:gap-10 lg:flex-row">
          <div className="flex w-full lg:w-[50%] xl:w-[50%] h-[600px] md:h-[800px] max-w-[600px] mx-auto  items-center justify-center gap-5 md:mb-10 ">
            <div className="flex flex-col items-center w-full h-full">
              <div className="relative w-full h-[700px]">
                <Image
                  src="/images/bio.jpg"
                  alt="about-us"
                  className="object-cover rounded-xl bg-brand-main"
                  fill
                  sizes="(max-width: 640px) 50vw, (max-width: 768px) 50vw, 33vw"
                />
                <div className="absolute left-0 hidden w-full h-full -translate-x-5 -translate-y-5 sm:block bg-brand-main-lighter -z-10 rounded-3xl" />
              </div>
              <div className="flex items-end w-full gap-4 mt-4 md:gap-8">
                <div className="relative w-[60px] h-[60px] mx-auto md:w-20 md:h-20">
                  <Image
                    src="/images/ucv.svg"
                    alt="about-us"
                    className="object-contain object-bottom"
                    fill
                    sizes="(max-width: 640px) 50vw, (max-width: 768px) 25vw, 20vw"
                  />
                </div>
                <div className="relative w-32 h-20 mx-auto md:h-28 md:w-52">
                  <Image
                    src="/images/unimet.svg"
                    alt="about-us"
                    className="object-contain object-bottom"
                    fill
                    sizes="(max-width: 640px) 50vw, (max-width: 768px) 25vw, 20vw"
                  />
                </div>
                <div className="relative w-32 h-20 mx-auto md:w-48 md:h-24">
                  <Image
                    src="/images/icmf.png"
                    alt="about-us"
                    className="object-contain !object-bottom"
                    fill
                    sizes="(max-width: 640px) 50vw, (max-width: 768px) 25vw, 20vw"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-delay="200"
            className="text-brand-main w-full lg:w-[50%] xl:w-[50%] "
          >
            <h2
              className="mb-4 text-2xl md:mb-8 md:text-4xl payfair text-brand-secondary"
              data-aos="fade-up"
            >
              Construyamos juntos el camino hacia un bienestar integral y el logro de tus metas
            </h2>
            <p className="text-sm md:text-base">
              Como Licenciada en Psicología especializada en Clínica y Recursos Humanos y Coach
              Profesional certificada por la ICMF, mi compromiso es guiarte a nivel personal y
              profesional para fortalecer tus capacidades y desplegar todo tu potencial. Utilizando
              una combinación de terapia cognitivo-conductual y técnicas avanzadas de coaching, te
              proporciono las herramientas necesarias para transformar tu vida y alcanzar un éxito
              duradero.
            </p>
            <h2 className="flex items-center gap-2 mb-4 text-xl md:mb-8 mt-14 md:text-3xl text-brand-secondary payfair">
              <CheckCircle />
              Experiencia y Especialización
            </h2>
            <p className="text-sm md:text-base">
              Con una sólida trayectoria en psicología y coaching, he forjado una perspectiva única
              que une la comprensión emocional profunda con estrategias prácticas y efectivas. Mis
              sesiones son colaborativas, centradas en el bienestar y la excelencia, y diseñadas
              para impulsar el crecimiento personal y la capacidad de tomar decisiones estratégicas
              informadas.
            </p>
            <h2 className="flex items-center gap-2 mb-4 text-xl md:mb-8 mt-14 md:text-3xl text-brand-secondary payfair">
              <CheckCircle />
              Certificaciones y Enfoques
            </h2>
            <p className="text-sm md:text-base">
              Como Coach Profesional certificada por la ICMF, me comprometo con la excelencia y las
              mejores prácticas en el campo. Utilizo técnicas respaldadas por la evidencia para
              enfrentar una variedad de desafíos, optimizando tanto el bienestar emocional como el
              desempeño profesional de mis clientes.
            </p>
            <Link
              href="/sobre-mi"
              className="block px-5 py-3 mt-10 font-semibold text-white transition-all duration-200 payfair w-fit bg-brand-btn hover:bg-brand-btn "
            >
              Descubre más sobre mí
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
